import { useState, useEffect } from 'react';
import React from 'react';
import axios from "axios";
import { useHistory } from "react-router-dom";

import '../assets/styles/app.css';
import apiInstance from '../utils/validateCP';

import { countries } from '../api/countries';
import { industries } from '../api/industries';
import { roles } from '../api/roles';

import { useForm } from "../hooks/useForm";
import validator from "validator";

import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
    SelectChangeEvent,
    Modal
} from "@mui/material";

import Check from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";

import Navbar from "../components/navigation/Navbar";

import ImgSupport from "../assets/images/support.svg";
import SobreEmail from "../assets/images/sobre_email.png";
import { ExpandMore } from "@mui/icons-material";

/* Página que contiene el formulario de contacto para solicitar información sobre alguna de las APIs */

const Support = () => {

    const history = useHistory();

    const { breakpoints } = useTheme();
    const isR1050 = useMediaQuery(breakpoints.down(1051));
    const isTablet = useMediaQuery(breakpoints.down(769));
    const isMobile = useMediaQuery(breakpoints.down("sm"));
    const isR350 = useMediaQuery(breakpoints.down(351));

    const [zipVal, setZipVal] = useState<String>("invalid");
    const [country, setCountry] = useState("");
    const [industry, setIndustry] = useState("");
    const [rol, setRol] = useState("");
    const [api, setApi] = useState("");
    const [open, setOpen] = React.useState(false);

    const modalSupportBox = {
        position: 'absolute',
        boxSizing: "border-box",
        maxWidth: "412px",
        height: "433px",
        top: '50%',
        left: '50%',
        textAlign: "center",
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: "8px",
        padding: isR350 ? "25px 14px" : isTablet ? "25px 24px":  "25px 55px",
        width: isR350 ? "95%" : isTablet ? "339px" : "100%",
    }

    const modalSupportImg = {
        height:"auto", 
        width: "138px", 
        display:"block", 
        margin: "0px auto"
    }

    const modalSupportTitle = {
        fontSize: "24px",
        fontWeight: "700",
        lineHeight: "30px",
        marginTop: "23px",
    }

    const modalSupportSubtitle = {
        mt: "15px",
        mb: "23px",
        lineHeight: "22px",
    }

    const modalSupportButton = {
        my: 2,
        margin: "0px auto",
        borderRadius: "60px",
        backgroundColor: '#039ECC',
        color: 'white',
        height: "48px",
        maxWidth: "220px",
        fontSize: "16px",
        fontWeight: "600",
        textTransform: "none",
        display: "block",
        textAlign: "center",
        lineHeight:"2.1",
        '&:hover': {
            backgroundColor: 'lightgray',
            color: "black"
        },
    }

    const bannerSupportBox = {
        paddingBottom: isR1050 ? "148px" : "164px",
    }

    const bannerSupportTitle = {
        fontSize: isMobile ? "18px" : isR1050 ? "22px" : "24px",
        fontWeight: isMobile ? "bold" :  "700",
        lineHeight: isMobile ? "22px" : isR1050 ? "27px" : "30px",
        color: "white",
        textAlign: "center",
        marginTop: "34px",
        padding: isMobile ? "0px 31px" : isR1050 ? "0px 55px" : "0px 24px"
    }

    const supportContainer = {
        padding: isMobile ? "0px 0px" : "0px 30px 1px"
    }

    const supportGridContainer = {
        background:isR1050 ? "transparent" : "white",
        borderRadius:"20px",
        padding: isMobile ? "0px 10px 40px" : isR1050 ? "0px 6% 48px 6%" : "53px 6%",
        marginTop: isR1050 ? "-111px" : "-122px",
        width:"100%",
        marginLeft:"auto",
        boxShadow:isR1050 ? "none": "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        marginBottom:isR1050 ? "0px" : "40px",
        justifyContent: isR1050 ? "center" : "space-between"
    }

    const supportGridImg = {
        width:"100%", 
        alignSelf: "center"
    }

    const supportImg = {
        height:"auto", 
        width:isR1050 ? "100%" : "100%", 
        maxWidth: isR1050 ? "558px" : "579px", 
        display:"block", 
        margin:isR1050 ? "auto" : "0px"
    }

    const supportGridText = {
        width: "100%", 
        maxWidth: "500px",
        color: "#8F8F8F",
        overflow: "hidden",
        marginTop: isMobile ? "18px" : isR1050 ? "24px" : "",
        padding: isMobile ? "" : isR1050 ? "0px 15px" : "0px 4px 4px 13px",
    }

    const supportContactTitle = {
        fontWeight:"700", 
        fontSize: "16px"
    }

    const supportBussinessTitle = {
        fontWeight:"700", 
        marginTop: isMobile ? "36px" : "40px", 
        fontSize: "16px"
    }

    const supportFormButton = {
        my: 2,
        fontWeight: "600",
        borderRadius:isMobile ? "48px" : "20px",
        padding: "6px 39px",
        marginTop: "42px",
        marginBottom: "0px",
        height: isMobile ? "56px" : "48px",
        backgroundColor: '#039ECC',
        color: 'white',
        '&:hover': {
            backgroundColor: 'lightgray',
            color: "black"
        },
    }

    const supportFormControl = {
        marginTop:"24px"
    }

    const supportFormIndustry = {
        marginTop:"14px"
    }

    const supportSelects = { 
        borderRadius: "8px",
        height:"48px" 
    }

    const supportFieldCp = {
        marginTop: isMobile ? "8px" : "24px"
    }

    const supportFields = {
        marginTop:"24px"
    }

    const supportFieldName = {
        marginTop:"14px"
    }

    /* Función que manda llamar el servicio para validar la existencia del código postal */
    async function validateZip(cp="") {

		await apiInstance.get("catalogs/location/"+cp+"").then(response => {
			if(response.data.success && response.data.code === 200) {
				setZipVal(cp);
			} else {
				setZipVal("invalid");
			}
		}).catch(error => {
			setZipVal("error");
		});
	}

    /* Función que manda llamar el servicio para enviar la información del formulario */
    async function sendMail() {

        await axios.post("https://demos.inter.mx/v1/mail/custom", {
            "from": "noreply@inter.mx",
            "to": [
                {
                    "email": "lopg@inter.mx"
                }
            ],
            "subject": "Contacto InterConnect",
            "body": "<html><head><b>Datos de contacto y de negocio</b></head><body><p><b>Nombre completo: </b>"+name+"</p> <p><b>Celular: </b>"+phone+"</p> <p><b>País: </b>"+country+"</p> <p><b>Código Postal: </b>"+zip+"</p> <p><b>Tipo de industria: </b>"+industry+"</p> <p><b>Nombre de la empresa: </b>"+company+"</p> <p><b>Página web de la empresa: </b>"+website+"</p> <p><b>Rol dentro de la organización: </b>"+rol+"</p> <p><b>API de interés: </b>"+api+"</p> </body></html>",
            "schedule": "****"
        }).then((response) => {
            if(response.status === 201){
                setOpen(true);
            };
        }).catch(error => {
			alert("Falló el envío del formulario. Por favor, inténtalo de nuevo.");
		});

    } 

	const [formState, setFormState] = useForm({
		name: "",
        email: "",
		phone: "",
        zip: "",
        industry: "",
        company: "",
        website: "",
        rol: "",
        api: "",
	});

	const {
		name,
		email,
		phone,
		zip,
		company,
		website,
	} = formState;

    const handleCountry = (event: SelectChangeEvent) => {
		setCountry(event.target.value as string);
	};

    const handleIndustry = (event: SelectChangeEvent) => {
		setIndustry(event.target.value as string);
	};

    const handleRol = (event: SelectChangeEvent) => {
		setRol(event.target.value as string);
	};

    const handleApi = (event: SelectChangeEvent) => {
		setApi(event.target.value as string);
	};

    /* Función para cerrar el modal de la confirmación del envío exitoso del formulario */
    const handleClose = () => {
        setOpen(false);
        history.push("/solution")
    };

    /* Función para verificar si los campos del formulario están llenados correctamente */
    const isValidForm = (): boolean => {
        if (name === "") {
            return false;
        } else if (validator.isEmpty(email) || !validator.isEmail(email)) {
            return false;
        } else if (validator.isEmpty(phone) || !validator.isMobilePhone(phone) || phone.length !== 10) {
            return false;
        } else if (!validator.isPostalCode(zip, "MX")) {
            return false;
        } else if (country === "") {
            return false;
        } else if (industry === "") {
            return false;
        } else if (rol === "") {
            return false;
        } else if (api === "") {
            return false;
        } else if (company === "") {
            return false;
        } else if (validator.isEmpty(website) || !validator.isURL(website)) {
            return false;
        } else if (zipVal === "invalid" || zipVal === "error") {
            return false;
        }

        return true;
    }

    /* Función que siempre abre la página desde la parte superior de la misma */
    useEffect(() => {
        window.scrollBy(0, -10000);
    }, []);

    return (
        <>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={modalSupportBox} >
                <img src= {SobreEmail} alt= {"E-mail Inter Connect"}
                    style={modalSupportImg}
                />
                <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    style={modalSupportTitle}
                >
                    ¡Gracias por tu interés!
                </Typography>
                <Typography
                    id="modal-modal-description"
                    sx={modalSupportSubtitle}
                >
                    Hemos recibido tu solicitud, uno de nuestros especialistas se pondrá en contacto contigo a la brevedad.
                </Typography>
                <Button
                    key="Ver API"
                    href="/support"
                    color="info"
                    variant="contained"
                    size="large"
                    sx={modalSupportButton}
                >
                    ENTENDIDO
                </Button>
            </Box>
        </Modal>
        <Box
            style={bannerSupportBox}
            className="header-blue"
        >
            <Navbar/>
            <Typography
                sx={bannerSupportTitle}
            >
                Nuestros especialistas te contactarán para brindarte más información
            </Typography>
        </Box>
        <Container maxWidth="lg" style={supportContainer} >
            <Grid container
                style={supportGridContainer}
            >
                <Grid item xs="auto" md={isR1050 ? "auto" : 7.5} style={supportGridImg}>
                    <img src= {ImgSupport} alt= {"Soporte inter"}
                        style={supportImg}
                    />
                </Grid>
                <Grid item xs={12} md={isR1050 ? 12 : 4.5} style={supportGridText} >
                    <Typography style={supportContactTitle}>
                        DATOS DE CONTACTO
                    </Typography>
                    <TextField
                        fullWidth
                        size={"small"}
                        label="Nombre completo"
                        name={"name"}
                        value={name}
                        style={supportFieldName}
                        InputProps={{
                            style: {
                                borderRadius: 8,
                                height:"48px"
                            },
                            endAdornment:
								name !== "" ? <Check color={"success"} /> : null,
                        }}
                        onChange={(e) => setFormState(e)}
                    />
                    <TextField
                        fullWidth
                        value={email}
                        size={"small"}
                        label="Correo electrónico corporativo"
                        name={"email"}
                        error={ !validator.isEmail(email) && email.length >= 1 }
                		helperText={ !validator.isEmail(email) && email.length >= 1 ? "usuario@ejemplo.com" : "" }
                        style={supportFields}
                        InputProps={{
                            style: {
                                borderRadius: 8,
                                height:"48px"
                            },
                            endAdornment: validator.isEmail(email) ? (
                                <Check color={"success"} />
                            ) : !validator.isEmpty(email) &&
                            !validator.isEmail(email) ? (
                                <InfoIcon color={"error"} />
                            ) : null,
                        }}
                        color={
                            !validator.isEmail(email) &&
                            !validator.isEmpty(email)
                                ? "error"
                                : validator.isEmail(email)
                                ? "success"
                                : "primary"
                        }
                        inputProps={{type : "email"}}
                        onChange={(e) => setFormState(e)}
                    />
                    <TextField
                        fullWidth
                        size={"small"}
                        label="Celular"
                        name={"phone"}
                        value={phone}
                        style={supportFields}
                        InputProps={{
                            style: {
                                borderRadius: 8,
                                height:"48px",
                            },
                            endAdornment: validator.isMobilePhone(phone) && phone.length === 10 ? (
                                <Check color={"success"} />
                            ) : !validator.isEmpty(phone) &&
                              !validator.isMobilePhone(phone) || phone.length < 10 && phone.length > 1  ? (
                                <InfoIcon color={"error"} />
                            ) : null,
                        }}
                        color={
                            !validator.isMobilePhone(phone) &&
                            !validator.isEmpty(phone)
                                ? "error"
                                : validator.isMobilePhone(phone) && phone.length === 10
                                ? "success"
                                : "primary"
                        }
                        error={ phone !== "" && phone.length < 10 }
						helperText={ phone !== "" && phone.length < 10 ? "número a 10 dígitos" : "" }
                        onChange={(e) => { e.target.value = e.target.value.replace(/[^0-9]+/g,''); setFormState(e)}}
                        inputProps={{ maxLength: 10, type: "tel"}}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} color="#8F8F8F">
                            <FormControl
                                fullWidth
                                style={supportFormControl}
                            >
                                <InputLabel>País</InputLabel>
                                <Select
                                    size={"small"}
                                    value={country}
                                    label="País"
                                    onChange={handleCountry}
                                    style={supportSelects}
                                    IconComponent={(props) => (
                                        <ExpandMore style={{ color: "#8F8F8F" }} {...props} />
                                    )}
                                >
                                    {countries.map((element,i) => (
                                        <MenuItem key={i} value={element}>
                                            {element}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} color="#8F8F8F">
                            <TextField
                                fullWidth
                                size={"small"}
                                label="Código postal"
                                name={"zip"}
                                value={zip}
                                maxRows={4}
                                helperText={ zip !== "" && zip.length < 5 ? "código a 5 números" : zip.length === 5 && zipVal === "invalid" ? "CP no válido" : zip.length === 5 && zipVal === "error" ? "Error"  : "" }
                                error={ (zip !== "" && zip.length < 5) || (zip.length === 5 && zipVal === "invalid") || (zip.length === 5 && zipVal === "error") }
                                style={supportFieldCp}
                                InputProps={{
                                    style: {
                                        borderRadius: 8,
                                        height:"48px"
                                    },
                                    endAdornment: validator.isPostalCode(zip, "MX") && zip.length === 5 && zipVal !== "invalid" && zipVal !== "error" ? (
                                        <Check color={"success"} />
                                    ) : null,
                                }}
                                color={
                                    validator.isPostalCode(zip, "MX") && zip.length === 5 && zipVal !== "error" && zipVal !== "invalid"? 'success' : "primary"
                                }
                                onChange={(e) =>{
                                    e.target.value = e.target.value.replace(/[^\d]+/g,''); 
                                    setFormState(e);
                                    (e.target.value.length === 5) && validateZip(e.target.value);
                                    (e.target.value.length < 5) && setZipVal("invalid");
                                }}
                                inputProps={{ maxLength: 5, type: "tel"}}
                            />
                        </Grid>
                    </Grid>
                    <Typography style={supportBussinessTitle}>
                        CUENTANOS MÁS SOBRE TU NEGOCIO
                    </Typography>
                    <FormControl
                        fullWidth
                        style={supportFormIndustry}
                    >
                        <InputLabel>Tipo de industria</InputLabel>
                        <Select
                            size={"small"}
                            value={industry}
                            label="Tipo de industria"
                            onChange={handleIndustry}
                            style={supportSelects}
                            IconComponent={(props) => (
                                <ExpandMore style={{ color: "#8F8F8F" }} {...props} />
                            )}
                        >
                            {industries.map((element, i) => (
                                <MenuItem key={i} value={element}>
                                    {element}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        size={"small"}
                        label="Nombre de la empresa"
                        name={"company"}
                        value={company}
                        style={supportFields}
                        InputProps={{
                            style: {
                                borderRadius: 8,
                                height:"48px"
                            },
                            endAdornment:
								company !== "" ? <Check color={"success"} /> : null,
                        }}
                        onChange={(e) => setFormState(e)}
                    />
                    <TextField
                        fullWidth
                        size={"small"}
                        label="Página web de la empresa"
                        name={"website"}
                        value={website}
                        style={supportFields}
                        error={ !validator.isURL(website) && website.length >= 1 }
                		helperText={ !validator.isURL(website) && website.length >= 1 ? "www.sitio.com" : "" }
                        InputProps={{
                            style: {
                                borderRadius: 8,
                                height:"48px"
                            },
                            endAdornment: validator.isURL(website) ? (
                                <Check color={"success"} />
                            ) : !validator.isEmpty(website) &&
                            !validator.isURL(website) ? (
                                <InfoIcon color={"error"} />
                            ) : null,
                        }}
                        color={
                            !validator.isURL(website) &&
                            !validator.isEmpty(website)
                                ? "error"
                                : validator.isURL(website)
                                ? "success"
                                : "primary"
                        }
                        onChange={(e) => setFormState(e)}
                    />
                    <FormControl
                        fullWidth
                        style={supportFormControl}
                    >
                        <InputLabel>Rol dentro de la organización</InputLabel>
                        <Select
                            size={"small"}
                            value={rol}
                            label="Rol dentro de la organización"
                            onChange={handleRol}
                            style={supportSelects}
                            IconComponent={(props) => (
                                <ExpandMore style={{ color: "#8F8F8F" }} {...props} />
                            )}
                        >
                            {roles.map((element, i) => (
                                <MenuItem key={i} value={element}>
                                    {element}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        fullWidth
                        style={supportFormControl}
                    >
                        <InputLabel>¿Qué tipo de API te interesa?</InputLabel>
                        <Select
                            size={"small"}
                            value={api}
                            label="¿Qué tipo de API te interesa?"
                            onChange={handleApi}
                            style={supportSelects}
                            IconComponent={(props) => (
                                <ExpandMore style={{ color: "#8F8F8F" }} {...props} />
                            )}
                        >
                            <MenuItem key={1} value={"Coche y motos"}>Coche y motos</MenuItem>
                            <MenuItem key={2} value={"Gasto médico"}>Gasto médico</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        key="contactar"
                        color="info"
                        variant="contained"
                        size="medium"
                        disabled={!isValidForm()}
                        fullWidth
                        sx={supportFormButton}
                        onClick={sendMail}
                    >
                        CONTACTAR
                    </Button>
                </Grid>
            </Grid>
        </Container>
        </>
    );
};

export default Support;