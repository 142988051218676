import { useState, useEffect } from "react";

import DocumentacionVehiculos from "../../../assets/images/documentacion_vehiculos.png";
import DocumentacionVehiculosMovil from "../../../assets/images/documentacion_vehiculos_movil.png";
import DocumentacionMedico from "../../../assets/images/documentacion_medico.png";
import DocumentacionMedicoMovil from "../../../assets/images/documentacion_medico_movil.png";

import {
    Container,
    Grid,
    Typography,
    useTheme,
    useMediaQuery,
    Button,
} from "@mui/material";

/* Sección con imagen, descripción y botón que redirecciona a la documentación de las APIs */

const Documentation = () => {

    const { breakpoints } = useTheme();

    const isR1100 = useMediaQuery(breakpoints.down(1101));
    const isR1000 = useMediaQuery(breakpoints.down(1001));
    const isR930 = useMediaQuery(breakpoints.down(931));
    const isR860 = useMediaQuery(breakpoints.down(861));
    const isR721 = useMediaQuery(breakpoints.down(721));
	const isMobile = useMediaQuery(breakpoints.down("sm"));
    const isR550 = useMediaQuery(breakpoints.down(550));
    const isR431 = useMediaQuery(breakpoints.down(431));
    const isR360 = useMediaQuery(breakpoints.down(360));

    const [description, setDescription] = useState<string>("");
    const [logo, setLogo] = useState<string>("");
    const [logoMovil, setLogoMovil] = useState<string>("");

    const documentationDiv = {
        paddingBottom: "22px"
    }

    const documentationGridContainer = {
        alignItems: "center"
    }

    const documentationImg = {
        height: "auto",
        width: "100%",
        maxWidth: "654px",
        margin:"auto",
        marginLeft: isR360 ? "1.2%" : isR431 ? "1.7%" : isR721 ? "1.3%" : "",
        minWidth: isR360 ? "" : "310px",
    }

    const documentationText = {
        textAlign: "center", 
        padding: isR721 ? "0px 0px 18px 0px" : "0px 0px 18px 12px"
    }

    const documentationTitle = {
        fontSize: isR431 ? "18px" : isR860 ? "22px" : isR930 ? "23px" : "28px",
        fontWeight: "700",
        lineHeight: isR860 ? "22px" : isR930 ? "28px" : "34px",
        marginBottom: "24px",
        marginTop: isR721 ? "15px" : ""
    }

    const documentationSubtitle = {
        fontSize: isR550 ? "16px" : isR721 ? "18px" : isR860 ? "16px" : isR930 ? "20px" : isR1100 ? "22px" : "24px",
        fontWeight: "400",
        lineHeight: isR431 ? "22px" : isR860 ? "24px" : isR930 ? "30px" : "29.3px",
        marginBottom: "32px",
        padding: isR431 ? "0px 4px" : isR721 ? "0px 30px" : ""
    }

    const documentationButton = {
        margin: "0px auto 0px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 600,
        fontSize: isMobile ? "16px" : isR721 ? "14px" : "16px",
        lineHeight: "19.5px",
        borderRadius: "48px",
        borderColor: "#039ECC",
        padding: "16px",
        height: "48px",
        width: "100%",
        maxWidth: "330px",
        backgroundColor: '#039ECC',
        color: 'white',
        '&:hover': {
            backgroundColor: 'lightgray',
            color: "black"
        },
    }

    /* Asignamos los valores para la descripción e imagen de la sección en base al tipo de seguro seleccionado
     en el carrusel de la página Home */
    useEffect(() => {
        switch( sessionStorage.getItem("categoria") ) {
            case "vehiculos": setDescription("Con nuestras APIs podrás cotizar y emitir pólizas de manera fácil y rápida, mejorando la experiencia de tu cliente");
                              setLogo(DocumentacionVehiculos);
                              setLogoMovil(DocumentacionVehiculosMovil);
                              break;
            case "medico": setDescription("Con nuestras APIs podrás perfilar y cotizar de manera fácil y rápida, mejorando la experiencia de tu cliente");
                            setLogo(DocumentacionMedico);
                            setLogoMovil(DocumentacionMedicoMovil);
                            break;
            case "mascota": return
            case "vida": return
            case "daños": return
        }   
    }, []);

    return (
        <div style={documentationDiv}>
            <Container maxWidth={"lg"}>
                <Grid container sx={documentationGridContainer} >
                    <Grid item xs={12} sm={isR721 ? 12 : isR930 ? 6 : 6.8} >
                        <img src={logo !== "" ? isR431 ? logoMovil : isR721 ? logo : isR1000 ? logoMovil : logo :
                                                 isR431 ? DocumentacionVehiculosMovil : isR721 ? DocumentacionVehiculos : isR1000 ? DocumentacionVehiculosMovil : DocumentacionVehiculos }
                            alt={"Documentación de las APIs"}
                            style={documentationImg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={isR721 ? 12 : isR930 ? 6 : 5.2} sx={documentationText} >
                        <Typography
                            variant="h1"
                            sx={documentationTitle}
                        >
                            Somos la solución ideal
                        </Typography>
                        <Typography
                            variant="h2"
                            sx={documentationSubtitle}
                        >
                            {description !== "" ? description : "Con nuestras APIs podrás cotizar y emitir pólizas de manera fácil y rápida, mejorando la experiencia de tu cliente" }
                        </Typography>
                        <Button
                            key="contactar"
                            color="info"
                            variant="outlined"
                            size="small"
                            target="_blank"
                            sx={documentationButton}
                            href="https://api-doc.inter.mx"
                        >
                            Ver documentación
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );

}

export default Documentation;