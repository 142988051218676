import { useState, useEffect } from 'react';

import {
    Container,
    Grid,
    Typography,
    Button,
    useTheme,
    useMediaQuery
} from "@mui/material";

import CocheMotoBanner from "../../../assets/images/coche_moto_banner.png";
import GastoMedicoBanner from "../../../assets/images/gasto_medico_banner.png";
import GastoMedicoBanner2 from "../../../assets/images/gasto_medico_banner2.png";

/* Banner que contiene la imagen y descripción en base al tipo de seguro seleccionado previamente en el carrusel
 del home y el botón para ir a la página del formulario de contacto */

const Banner = () => {

    /* Obtenemos el tipo de seguro de la sesión para mostrar su información relacionada */
    const categoria = sessionStorage.getItem("categoria");

    const { breakpoints } = useTheme();

    const isR1250 = useMediaQuery(breakpoints.down(1251));
    const isR1050 = useMediaQuery(breakpoints.down(1051));
    const isR950 = useMediaQuery(breakpoints.down(951));
    const isR830 = useMediaQuery(breakpoints.down(831));
    const isR805 = useMediaQuery(breakpoints.down(806));
    const isR750 = useMediaQuery(breakpoints.down(751));
    const isR550 = useMediaQuery(breakpoints.down(551));
    const isR430 = useMediaQuery(breakpoints.down(431));

    const [title, setTitle] = useState<string>("");
    const [subtitle, setSubtitle] = useState<string>("");
    const [logo, setLogo] = useState<string>("");
    const [logo2, setLogo2] = useState<string>("");
    const [widthImg, setWidthImg] = useState<string>("");

    const bannerContainer = {
        padding: isR750 ? "0px" : isR830 ? "0px 0px 12px" : "0px",
    };

    const bannerGridContainer = {
        justifyContent: "center",
        direction: "row", 
        display: "flex", 
        alignItems: "center", 
        maxWidth: "1440px", 
        margin: "auto", 
        paddingRight: isR750 ? "0px"  : isR805 ? "14px" : "", 
        height: isR750 ? "auto" : "43vw", 
        maxHeight: isR750 ? "auto" : "484px"
    };

    const bannerGridImg = {
        width: isR750 ? "100%" : widthImg, 
        minWidth: isR750 ? "auto" : "378px", 
        padding: isR550 ? "" : isR750 ? "0px 30px" : ""
    }

    const bannerImg = {
        height: "auto",
        width: "100%",
        display: "block",
    }

    const bannerGridText = {
        width: isR750 ? "100%" : "33%", 
        paddingLeft: isR750 ? "0px" : "24px", 
        padding: isR750 ? "0px 6.1%" : "", 
        textAlign: isR750 ? "center" : "left", 
        minWidth: "281px"
    }

    const bannerTitle = {
        fontWeight: "bold",
        fontSize: isR550 ? "32px" : isR750 ? "38px" : isR950 ? "32px" : isR1050 ? "36px" : isR1250 ? "40px" : "48px",
        lineHeight: isR950 ? "39px" : isR1050 ? "36px" : isR1250 ? "54px" : "59px",
        color: "white",
        marginBottom: isR550 ? "16px" : isR750 ? "20px" : isR830 ? "16px" : "24px"
    }

    const bannerSubtitle = {
        fontSize: isR430 ? "16px" : isR750 ? "20px" :  isR950 ? "18px" : isR1050 ? "20.5px" : isR1250 ? "22.5px" : "28px", 
        lineHeight: isR430 ? "20px" :isR750 ? "26px" :  isR950 ? "22px" : isR1050 ? "25px" : isR1250 ? "31px" : "34px", 
        color: "white", 
        padding: isR430 ? "0px 11px" : isR750 ? "0px 20px" : ""
    }

    const bannerButton = {
        margin: isR430 ? "16px auto 62px" : isR750 ? "24px auto 62px" : isR830 ? "18px auto 0px" : "40px auto 0px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 600,
        fontSize: isR750 ? "16px" : "16px",
        lineHeight: "20px",
        borderRadius: "48px",
        padding: "16px",
        height: isR750 ? "56px" : "48px",
        width: "100%",
        maxWidth: isR750 ? "100%" : categoria !== "medico" ? "280px" : "320px",
        backgroundColor: 'white',
        color: '#039ECC',
        '&:hover': {
            backgroundColor: 'lightgray',
            color: "black"
        },
    }

    /* Asignamos los valores para el titulo, subtitulo e imagen del banner en base al tipo de seguro seleccionado
     en el carrusel de la página Home */
    useEffect(() => {
        switch( sessionStorage.getItem("categoria") ) {
            case "vehiculos": setTitle("Coche y motos");
                              setSubtitle("Tenemos las aseguradoras más importantes del mercado, contamos con soluciones para mercado individual y flotas");
                              setLogo(CocheMotoBanner);
                              setLogo2(CocheMotoBanner);
                              setWidthImg(((875/1440)*100) + "%");
                              break;
            case "medico": setTitle("Gasto médico");
                            setSubtitle("Ofrecemos múltiples opciones a través de un perfilamiento inteligente. Tenemos productos individuales y para grupos");
                            setLogo(GastoMedicoBanner);
                            setLogo2(GastoMedicoBanner2);
                            setWidthImg(((568/1440)*100) + "%");
                            break;
            case "mascota": return
            case "vida": return
            case "daños": return
            default : setTitle("Coche y motos");
                    setSubtitle("Tenemos las aseguradoras más importantes del mercado, contamos con soluciones para mercado individual y flotas");
                    setLogo(CocheMotoBanner);
                    setLogo2(CocheMotoBanner);
                    setWidthImg(((875/1440)*100) + "%");
                    break;
        }   
    }, []);

    return (
        <Container maxWidth={false} style={bannerContainer}>
            <Grid container sx={bannerGridContainer}>
                <Grid item sx={bannerGridImg}>
                    <img src={logo !== "" ? isR750 ? logo2 : logo : ""} alt={title}
                        style={bannerImg}
                    />
                </Grid>
                <Grid item sx={bannerGridText}>
                    <Typography sx={bannerTitle}>
                        {title !== "" ? title : "Coche y motos"}
                    </Typography>
                    <Typography sx={bannerSubtitle}>
                        {subtitle !== "" ? subtitle : "Tenemos las aseguradoras más importantes del mercado, contamos con soluciones para mercado individual y flotas"}
                    </Typography>
                    <Button
                        key="contactar"
                        variant="outlined"
                        size="small"
                        sx={bannerButton}
                        href="/support"
                    >
                        Regístrate y pruébala
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );

}

export default Banner;