import { SlideShowItem } from "../../../models/SlideShowItem"

import {
    Grid, 
    Typography,
    useTheme,
    useMediaQuery
} from "@mui/material"

/* Elemento del carrusel que muestra una imagen y una descripción de las APIs disponibles por tipo de seguro */

type SlideItemProps = {
	item: SlideShowItem;
};

export const SlideItem: React.FC<SlideItemProps> = ({ item }) => {

    const { breakpoints } = useTheme();

    const isR930 = useMediaQuery(breakpoints.down(931));
    const isR860 = useMediaQuery(breakpoints.down(861));
    const isTablet = useMediaQuery(breakpoints.down(769));
    const isR700 = useMediaQuery(breakpoints.down(701));
    const isR625 = useMediaQuery(breakpoints.down(626));
	const isMobile = useMediaQuery(breakpoints.down("sm"));
    const isR431 = useMediaQuery(breakpoints.down(431));
    const isR360 = useMediaQuery(breakpoints.down(360));
    const isR359 = useMediaQuery(breakpoints.down(359));

    const itemContainer = {
        justifyContent: "center"
    }

    const itemGridImg = {
        display: "flex",
        maxWidth: "556px"
    }

    const itemImg = {
        margin: isR860 ? "auto" : "auto 0px auto auto",
        display: "block",
        height: isR359 ? "100%" : "auto",
        width: isR625 ? "100%" : isR860 ? "auto" : "100%",
    }

    const itemGridText = {
        margin: "auto 0px auto 0px", 
        textAlign: isR860 ? "center" : "left", 
        padding: isMobile ? "0px" : "0px 24px 0px 24px"
    }

    const itemTitle = {
        fontWeight: 700,
        fontSize: isR431 ? "18px" : "20px",
        lineHeight: "24px",
        paddingBottom: isTablet ? "24px" : "24px",
        width: isR860 ? "auto" : "361px",
        paddingTop: isR860 ? "16px" : ""
    }

    const itemSubtitle = {
        fontSize: isR431 ? "16px" : isR860 ? "20px" : isR930 ? "16px" : "20px",
        lineHeight: "24px",
        width: isR860 ? "auto" : "361px",
        padding: isR360 ? "0px" : isMobile ? "0px 5px" : isR700 ? "0px 14px" : isR860 ? "0px 68px" : "",
    }

    return (
        <Grid container sx={itemContainer} >
            <Grid item xs={12} sm={ isR860 ? 12 : 5.8} sx={itemGridImg} >
                <img 
                    src={item.image}
                    alt={item.title}
                    style={itemImg}
                />
            </Grid>
            <Grid 
                item 
                xs={12} 
                sm={isR860 ? 12 : "auto"}
                sx={itemGridText}
            >
                <Typography 
                    sx={itemTitle}
                >
                    {item.title}
                </Typography>
                <Typography
                    sx={itemSubtitle}
                >
                    {item.subtitle}
                </Typography>
            </Grid>
        </Grid>
    );
};
