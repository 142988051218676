import { useEffect } from 'react';

import '../assets/styles/app.css';

import {
    Box
} from "@mui/material";

import Navbar from "../components/navigation/Navbar";
import Banner from "../components/screens/product/Banner";
import SlideShow from '../components/screens/product/SlideShow';
import InsurersSection from '../components/screens/product/InsurersSection';
import Documentation from '../components/screens/product/Documentation';

/* Página que integra un banner descriptivo en base al tipo de seguro, las APIs disponibles, asuguradoras
 con las que se tiene convenio y su documentación */

const Product = () => {

    /* Función que siempre abre la página desde la parte superior de la misma */
    useEffect(() => {
        window.scrollBy(0, -10000);
    }, []);

    return (
        <>
        <Box
                className="header-blue"
        >
            <Navbar />
            <Banner />
        </Box>
        <SlideShow />
        <InsurersSection />
        <Documentation />
        </>
    );
};

export default Product;