import Marquee from "react-fast-marquee";

import {
    Grid,
    Typography,
    useTheme,
    useMediaQuery,
     Container
} from "@mui/material";

import Afirme from "../../../assets/images/Afirme.svg";
import GMX from "../../../assets/images/GMX.svg";
import GNP from "../../../assets/images/GNP.svg";
import HDI from "../../../assets/images/HDI.svg";
import MAPFRE from "../../../assets/images/MAPFRE.svg";
import Primero from "../../../assets/images/Primero.svg";
import Qualitas from "../../../assets/images/Qualitas.svg";
import AIG from "../../../assets/images/AIG.svg";
import Zurich from "../../../assets/images/Zurich.svg";
import AXA from "../../../assets/images/AXA.svg";
import GeneralDeSalud from "../../../assets/images/GeneralDeSalud.svg";

/* Marquee donde se muestran los logos de las aseguradoras con las que se tiene convenio para el uso de las APIs */

const InsurersSection = () => {

    const { breakpoints } = useTheme();

    /* Obtenemos el tipo de seguro de la sesión para mostrar los logos de las aseguradoras relacionadas */
    const categoria = sessionStorage.getItem("categoria");

    const isTablet = useMediaQuery(breakpoints.down(769));
	const isMobile = useMediaQuery(breakpoints.down("sm"));

    const title = {
        fontWeight: 700,
        fontSize: isMobile ? "20px" : isTablet ? "22px" : "28px",
        lineHeight: isMobile ? "24px" : isTablet ? "27px" : "34px",
        textAlign: "center",
        margin: "80px auto 24px",
    }

    const insurersMarquee = {
        maxWidth: categoria !== "medico" ? "1332px" : "592px", 
        margin: "auto", 
        width: "100%"
    }

    const marqueeGridContainer = {
        marginBottom: "80px",
        height: "80px"
    }

    const afirmeImg = {
        margin: "auto 20px",
        display: categoria !== "medico" ? "block" : "none"
    }

    const gmxImg = {
        margin: categoria !== "medico" ? "auto 20px" : "auto 30px",
        display: categoria !== "medico" ? "block" : "none"
    }

    const gnpImg = {
        margin:  categoria !== "medico" ? "auto 20px" : "auto 30px"
    }

    const hdiImg = {
        margin:  categoria !== "medico" ? "auto 20px" : "auto 30px",
        display: categoria !== "medico" ? "block" : "none"
    }

    const mapfreImg = {
        margin:  categoria !== "medico" ? "auto 20px" : "auto 30px"
    }

    const primeroImg = {
        margin:  categoria !== "medico" ? "auto 20px" : "auto 30px",
        display: categoria !== "medico" ? "block" : "none"
    }

    const qualitasImg = {
        margin:  categoria !== "medico" ? "auto 20px" : "auto 30px",
        display: categoria !== "medico" ? "block" : "none"
    }

    const aigImg = {
        margin:  categoria !== "medico" ? "auto 20px" : "auto 30px",
        display: categoria !== "medico" ? "block" : "none"
    }

    const zurichImg = {
        margin:  categoria !== "medico" ? "auto 20px" : "auto 30px",
        display: categoria !== "medico" ? "block" : "none"
    }

    const gsImg = {
        margin:  categoria !== "medico" ? "auto 20px" : "auto 30px",
        display: categoria === "medico" ? "block" : "none"
    }

    const axaImg = {
        margin:  categoria !== "medico" ? "auto 20px" : "auto 30px",
        display: categoria === "medico" ? "block" : "none"
    }

    return (
        <>
        <Container maxWidth={"lg"}>
            <Typography
                sx={title}
            >
                Nos integramos con las siguientes aseguradoras
            </Typography>
        </Container>
        <Marquee speed={30} gradient={false} style={insurersMarquee} >
            <Grid container sx={marqueeGridContainer} >
                <img src={Afirme} alt={"Afirme"}
                    style={afirmeImg}
                />
                <img src={GMX} alt={"GMX"}
                    style={gmxImg}
                />
                <img src={GNP} alt={"GNP"}
                    style={gnpImg}
                />
                <img src={HDI} alt={"HDI"}
                    style={hdiImg}
                />
                <img src={MAPFRE} alt={"MAPFRE"}
                    style={mapfreImg}
                />
                <img src={Primero} alt={"Primero"}
                    style={primeroImg}
                />
                <img src={Qualitas} alt={"Qualitas"}
                    style={qualitasImg}
                />
                <img src={AIG} alt={"AIG"}
                    style={aigImg}
                />
                <img src={Zurich} alt={"Zurich"}
                    style={zurichImg}
                />
                <img src={GeneralDeSalud} alt={"GeneralDeSalud"}
                    style={gsImg}
                />
                <img src={AXA} alt={"AXA"}
                    style={axaImg}
                />
            </Grid>
        </Marquee>
        </>
    );
};

export default InsurersSection;