import { useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { makeStyles } from "@mui/styles";

import { 
	useMediaQuery,
	useTheme,
} from "@mui/material";

import LogoInter from "../../assets/images/logo_inter.svg";
import LogoInterWhite from "../../assets/images/logo_inter_white.svg";

/* Barra de navegación que contiene el logo de inter.mx que redirecciona al home de inter-connect, y un link a la
 página de ayuda de inter.mx */

const useStyles = makeStyles({
	linkDesktop: {
		textDecoration: "none",
        color: "black"
	},
    linkMobile: {
		textDecoration: "none",
        color: "black",
        textAlign: "center"
	},
});

const Navbar = () => {

    const location = useLocation();

    const classes = useStyles();

    const { breakpoints } = useTheme();
	const isTablet = useMediaQuery(breakpoints.down(769));
    const isTabletBig = useMediaQuery(breakpoints.down("md"));

    const navBarAppBar = { 
        backgroundColor: "transparent", 
        boxShadow: "none" 
    }

    const navBarBoxLogo = { 
        mr: 2, 
        display: { xs: 'flex', md: 'flex' } 
    }

    const navBarLinkLogo = { 
        textDecoration: "none"
     }

    const navBarImgLogo = {
        width: isTablet? "115px" : "auto"
    }

    const navBarBoxOptions = {
        marginLeft: "auto"
    }

    const navBarButtons = {
        my: 2,
        display: 'block',
        fontWeight: "bold",
        textTransform: "none",
        margin: isTabletBig ? "8px" : "16px 20px",
        color: location.pathname === "/" ? 'black' : 'white',
        '&:hover' : {
            color: location.pathname === "/" ? 'black' : "#d1e0fd"
        }
    }

    return (
        <AppBar position="static" sx={navBarAppBar}>
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    <Box
                        component="div"
                        sx={navBarBoxLogo}
                    >
                        <Link href="https://inter.mx/" style={navBarLinkLogo} target="_blank">
                        { location.pathname === "/" ? 
                                <img src= {LogoInter} alt= {"Logo Inter.mx"} style={navBarImgLogo} />
                            :
                                <img src= {LogoInterWhite} alt= {"Logo Inter.mx"} style={navBarImgLogo} />
                        }  
                        </Link>
                    </Box>
                    <Box sx={navBarBoxOptions}>
                        <Button
                            key="Ayuda"
                            className={classes.linkDesktop} 
                            sx={navBarButtons}
                            href="/"
                        >
                            Inicio
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            key="Ayuda"
                            className={classes.linkDesktop} 
                            sx={navBarButtons}
                            href="https://intermx-digital.atlassian.net/servicedesk/customer/portal/5"
                            target="_blank"
                        >
                            Ayuda
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Navbar;
