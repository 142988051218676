import axios from "axios"

const apiInstance = axios.create({
    baseURL: "https://demos.inter.mx/v1/"
})

const getToken = async () => {
    const token = sessionStorage.getItem('tokenAuth')

    const { data } = await axios.post("https://demos.inter.mx/v1/authorization/token",{
        client_id: "z0PKRSO3cV7b6yj2blDt3n3UFPaErjN8",
        client_secret: "074mcPc01xgTd-K5Iq70IsygkhnyUaK4DNIYGVFsiwFX6kgSHONsQcQwi8S8D3ZO"
    })

    if(!token)
        sessionStorage.setItem('tokenAuth', `${data.data.access_token}`)

    return data
}

apiInstance.interceptors.request.use(
    async config => {
        let response
        const token = sessionStorage.getItem('tokenAuth')

        if(!token){
            response = await getToken()
        }

        if (response || token) {
            if(config.headers !== undefined){
                config.headers.Authorization = `Bearer ${token ? token : response.data.access_token}`
            }
        }
        
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

apiInstance.interceptors.response.use(
    response => response,
    error => {
        if(error.response.status === 401){
            sessionStorage.removeItem('tokenAuth')
            getToken()
        }
    }
)

export default apiInstance
