import Carousel from "react-material-ui-carousel";

import { slideItems } from "../../../api/slideItems";
import { SlideItem } from "./SlideItem";

import {
    Container,
    Grid,
    Typography,
    useTheme,
    useMediaQuery,
} from "@mui/material";

/* Carrusel que muestra las APIs disponibles por el tipo de seguro seleccionado en el carrusel de la página 
 Home */

const SlideShow = () => {

    const { breakpoints } = useTheme();

    /* Obtenemos el tipo de seguro de la sesión para mostrar su información relacionada */
    const categoria = sessionStorage.getItem("categoria");

    const isR1100 = useMediaQuery(breakpoints.down(1101));
    const isR1050 = useMediaQuery(breakpoints.down(1051));
    const isR1000 = useMediaQuery(breakpoints.down(1001));
    const isR930 = useMediaQuery(breakpoints.down(931));
    const isR900 = useMediaQuery(breakpoints.down(901));
    const isR860 = useMediaQuery(breakpoints.down(861));
	const isMobile = useMediaQuery(breakpoints.down("sm"));
    const isR575 = useMediaQuery(breakpoints.down(576));
    const isR550 = useMediaQuery(breakpoints.down(550));
    const isR525 = useMediaQuery(breakpoints.down(526));
    const isR475 = useMediaQuery(breakpoints.down(476));
    const isR450 = useMediaQuery(breakpoints.down(451));
    const isR431 = useMediaQuery(breakpoints.down(431));
    const isR380 = useMediaQuery(breakpoints.down(381));
    const isR360 = useMediaQuery(breakpoints.down(360));
    const isR330 = useMediaQuery(breakpoints.down(331));

    const topText = {
        fontWeight: 700,
        fontSize: isMobile ? "20px" : isR860 ? "22px" : isR930 ? "27px" : "28px",
        lineHeight: isMobile ? "22px" : isR860 ? "27px" : "34px",
        textAlign:"center",
        margin :"40px auto",
    }

    const productCarousel = {
        '& .MuiSvgIcon-root': {
            fontSize: "23px"
        },
    }

    return (
        <>
        <Container maxWidth={"lg"}>
            <Grid container>
                <Typography
                    sx={topText}
                >
                    Te brindamos las mejores soluciones con nuestras APIs
                </Typography>
                <Grid item xs={12}>
                    <Carousel
                        swipe
                        indicators={true}
                        autoPlay={true}
                        navButtonsAlwaysInvisible={true}
                        height={isR330 ? "392px" : isR360 ? "389px" : isR380 ? "403px" : isR431 ? "414px" : isR450 ? "452px" : isR475 ? "470px" : isR525 ? "480px" : isR550 ? "498px" : isR575 ? "515px" : isR860 ? "530px" : isR900 ? "290px" : isR930 ? "300px" : isR1000 ? "322px" : isR1050 ? "340px" : "388px"}
                        sx={productCarousel}
                        activeIndicatorIconButtonProps={{
                            style: {
                                color: "#77CAE3",
                            },
                        }}
                        indicatorIconButtonProps={{
                            style: {
                                margin: isR1100 ? "0px 12px" : "24px 12px 0px",
                                color: "#C7C7C7",
                                zIndex: 2,
                            }
                        }}
                    >
                        <SlideItem item={categoria === "vehiculos" ? slideItems[0] : slideItems[2]}/>
                        <SlideItem item={categoria === "vehiculos" ? slideItems[1] : slideItems[0]}/>
                    </Carousel>
                </Grid>
            </Grid>
        </Container>
        </>
    );
};

export default SlideShow;