import React, { Component } from "react";
import styled from "@emotion/styled";
import Slide from "./Slide";
import PropTypes from "prop-types";

import leftNavigation from "../../../../assets/images/btn_left.png";
import rightNavigation from "../../../../assets/images/btn_right.png";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const NavigationButtons = styled.div`
  position: relative;
  display: flex;
  height: 70.5px;
  width:100%;
  top: calc(-50% - 36.5px);
  -moz-box-pack: justify;
  justify-content: space-between;
  img {
    height: 100%;
  }
  @media only screen and (max-width: 899px) {
    display:none;
  }
`;

const DEFAULT_GO_TO_SLIDE_DELAY = 200;

interface IState {
  index: number;
  goToSlide: number | null;
  prevPropsGoToSlide: number;
  newSlide: boolean;
}

interface IProps {
  slides: any;
  goToSlide?: number;
  showNavigation: boolean;
  offsetRadius: number;
  animationConfig: object;
  goToSlideDelay: number;
}

function mod(a: number, b: number): number {
  return ((a % b) + b) % b;
}

class Carousel extends Component<IProps, IState> {
  state: IState = {
    index: 0,
    goToSlide: null,
    prevPropsGoToSlide: 0,
    newSlide: false
  };

  goToIn?: number;

  static propTypes = {
    slides: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.any,
        content: PropTypes.object
      })
    ).isRequired,
    goToSlide: PropTypes.number,
    showNavigation: PropTypes.bool,
    offsetRadius: PropTypes.number,
    animationConfig: PropTypes.object,
    goToSlideDelay: PropTypes.number,
  };

  static defaultProps = {
    offsetRadius: 2,
    animationConfig: { tension: 120, friction: 14 },
    goToSlideDelay: DEFAULT_GO_TO_SLIDE_DELAY,
  };

  static getDerivedStateFromProps(props: IProps, state: IState) {
    const { goToSlide } = props;

    if (goToSlide !== state.prevPropsGoToSlide) {
      return { prevPropsGoToSlide: goToSlide, goToSlide, newSlide: true };
    }

    return null;
  }

  componentDidUpdate() {
    const { goToSlideDelay } = this.props;
    const { index, goToSlide, newSlide } = this.state;

    if (typeof goToSlide === "number") {
      if (newSlide) {
        this.handleGoToSlide();
      } else if (index !== goToSlide && typeof window !== "undefined") {
        window.clearTimeout(this.goToIn);
        this.goToIn = window.setTimeout(this.handleGoToSlide, goToSlideDelay);
      } else if (typeof window !== "undefined") {
        window.clearTimeout(this.goToIn);
      }
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.clearTimeout(this.goToIn);
    }
  }

  modBySlidesLength = (index: number): number => {
    return mod(index, this.props.slides.length);
  };

  moveSlide = (direction: -1 | 1) => {
    this.setState({
      index: this.modBySlidesLength(this.state.index + direction),
      goToSlide: null
    });
  };

  getShortestDirection(from: number, to: number): -1 | 0 | 1 {
    
    if (from > to) {
      if (from - to > this.props.slides.length - 1 - from + to) {
        return 1;
      } else return -1;
    } else if (to > from) {
      if (to - from > from + this.props.slides.length - 1 - to) {
        return -1;
      } else return 1;
    }

    return 0;
  }

  handleGoToSlide = () => {

    if (typeof this.state.goToSlide !== "number") {
      return;
    }

    const { index } = this.state;

    const goToSlide = mod(this.state.goToSlide, this.props.slides.length);

    if (goToSlide !== index) {
      let direction = this.getShortestDirection(index, goToSlide);
      const isFinished =
        this.modBySlidesLength(index + direction) === goToSlide;

      this.setState({
        index: this.modBySlidesLength(index + direction),
        newSlide: isFinished,
        goToSlide: isFinished ? null : goToSlide
      });
    }
  };

  clampOffsetRadius(offsetRadius: number): number {

    const { slides } = this.props;
    const upperBound = Math.floor((slides.length - 1) / 2);

    if (offsetRadius < 0) {
      return 0;
    }

    if (offsetRadius > upperBound) {
      return upperBound;
    }

    return offsetRadius;
  }

  getPresentableSlides(): Array<any[]> {

    const { slides } = this.props;
    const { index } = this.state;
    let { offsetRadius } = this.props;
    offsetRadius = this.clampOffsetRadius(offsetRadius);
    const presentableSlides = [];

    for (let i = -offsetRadius; i < 1 + offsetRadius; i++) {
      presentableSlides.push(slides[this.modBySlidesLength(index + i)]);
    }

    return presentableSlides;
  }

  isDragging = false;
  iniX = 0;

  onDown = (event: any) => {
    this.isDragging = true;
  };

  onMove = (event: any) => {

    if (!this.isDragging) {
      return;
    }

    if(this.iniX === 0){
      this.iniX = event.pageX;
    }

    let distanceX = this.iniX - event.pageX;

    if(distanceX < 0){
      if(distanceX < -100){
        this.isDragging = false;
        this.iniX = event.pageX;
        this.moveSlide(-1);
      }
    }

    if (distanceX > 0) {
      if (distanceX > 100) {
        this.isDragging = false;
        this.iniX = event.pageX;
        this.moveSlide(1);
      }
    }
  };

  onUp = (event: any) => {
    this.isDragging = false;
    this.iniX = 0;
  }

  render() {

    const { animationConfig, offsetRadius, showNavigation } = this.props;
    let navigationButtons = null;

    if (showNavigation) {
      navigationButtons = (
        <NavigationButtons id="navCarBtns">
          <img
            src={leftNavigation}
            onClick={() => this.moveSlide(-1)}
            style={{ marginLeft: "-68px", cursor:"pointer" }}
            alt="left arrow"
          />
          <img
            src={rightNavigation}
            onClick={() => this.moveSlide(1)}
            style={{ marginRight: "-68px", cursor:"pointer" }}
            alt="right arrow"
          />
        </NavigationButtons>
      );
    }

    return (
      <React.Fragment>
        <Wrapper
          touch-action="none"
          onPointerDown={this.onDown}
          onPointerMove={this.onMove}
          onPointerUp={this.onUp}
          onPointerCancel={this.onUp}
          onPointerLeave={this.onUp}        
        >
          {this.getPresentableSlides().map(
            (slide: any , presentableIndex: number) => (
              <Slide
                key={slide.key}
                content={slide.content}
                onClick={slide.onClick}
                offsetRadius={this.clampOffsetRadius(offsetRadius)}
                index={presentableIndex}
                animationConfig={animationConfig}
              />
            )
          )}
        </Wrapper>
        {navigationButtons}
      </React.Fragment>
    );
  }
}

export default Carousel;
