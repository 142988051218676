export const countries = [
    'Albania',
    'Alemania',
    'Andorra',
    'Angola',
    'Antigua y Barbuda',
    'Arabia Saudita',
    'Argelia',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaiyán',
    'Bahamas',
    'Baréin',
    'Bangladesh',
    'Barbados',
    'Belarús',
    'Bélgica',
    'Belice',
    'Benín',
    'Bután',
    'Bolivia (Estado Plurinacional de Bolivia)',
    'Bosnia y Herzegovina',
    'Botsuana',
    'Brasil',
    'Brunéi Darussalam',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Camboya',
    'Camerún',
    'Canadá',
    'Chad',
    'Chequia',
    'Chile',
    'China',
    'Chipre',
    'Colombia',
    'Comoras',
    'Congo',
    'Costa Rica', 
    'Costa de Marfil',
    'Croacia',
    'Cuba',
    'Dinamarca',
    'Dominica',
    'Ecuador',
    'Egipto',
    'El Salvador',
    'Emiratos Árabes Unidos',
    'Eritrea',
    'Eslovaquia',
    'Eslovenia',
    'España',
    'Estados Unidos de América',
    'Estonia',
    'Esuatini',
    'Etiopía',
    'Federación de Rusia',
    'Fiyi',
    'Filipinas',
    'Finlandia',
    'Francia',
    'Gabón',
    'Gambia',
    'Georgia',
    'Ghana',
    'Granada',
    'Grecia',
    'Guatemala',
    'Guinea',
    'Guinea Ecuatorial',
    'Guinea-Bissau',
    'Guyana',
    'Haití',
    'Honduras',
    'Hungría',
    'India',
    'Indonesia',
    'Irán (República Islámica del Irán)',
    'Iraq',
    'Irlanda',
    'Islandia',
    'Islas Cook',
    'Islas Feroe',
    'Islas Marshall',
    'Islas Salomón',
    'Israel',
    'Italia',
    'Jamaica',
    'Japón',
    'Jordania',
    'Kazajstán',
    'Kenia',
    'Kirguistán',
    'Kiribati',
    'Kuwait',
    'Lesoto',
    'Letonia',
    'Líbano',
    'Liberia',
    'Libia',
    'Lituania',
    'Luxemburgo',
    'Macedonia del Norte',
    'Madagascar',
    'Malasia',
    'Malawi',
    'Maldivas',
    'Malí',
    'Malta',
    'Marruecos',
    'Mauricio',
    'Mauritania',
    'México',
    'Micronesia (Estados Federados de Micronesia)',
    'Mónaco',
    'Mongolia',
    'Montenegro',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Nicaragua',
    'Níger',
    'Nigeria',
    'Niue',
    'Noruega',
    'Nueva Zelandia',
    'Omán',
    'Países Bajos',
    'Pakistán',
    'Palau',
    'Panamá',
    'Papúa Nueva Guinea',
    'Paraguay',
    'Perú',
    'Polonia',
    'Portugal',
    'Qatar',
    'Reino Unido de Gran Bretaña e Irlanda del Norte',
    'República Árabe Siria',
    'República Centroafricana',
    'República de Corea',
    'República de Moldova',
    'República Democrática del Congo',
    'República Democrática Popular Lao',
    'República Dominicana',
    'República Popular Democrática de Corea',
    'República Unida de Tanzania',
    'Rumania',
    'Ruanda',
    'Samoa',
    'San Cristóbal y Nieves',
    'San Marino',
    'San Vicente y las Granadinas',
    'Santa Lucía',
    'Santo Tomé y Príncipe',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leona',
    'Singapur',
    'Somalia',
    'Sri Lanka',
    'Sudáfrica',
    'Sudán',
    'Sudán del Sur',
    'Suecia',
    'Suiza',
    'Surinam',
    'Tailandia',
    'Tayikistán',
    'Timor-Leste',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad y Tobago',
    'Túnez',
    'Turkmenistán',
    'Turquía',
    'Tuvalu',
    'Ucrania',
    'Uganda',
    'Uruguay',
    'Uzbekistán',
    'Vanuatu',
    'Venezuela (República Bolivariana de Venezuela)',
    'Vietnam',
    'Yemen',
    'Yibuti',
    'Zambia',
    'Zimbabue'       
];