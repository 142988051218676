import { Switch, Route, Redirect } from 'react-router-dom';

import { makeStyles } from "@mui/styles";

import Home from './pages/Home';
import Product from './pages/Product';
import Support from './pages/Support';

import BackgroundCircles from "./assets/images/circles_background.svg";

/* Contiene todas las páginas del sitio, el enrutamiento y el redireccionamiento */

const useStyles = makeStyles({
	content: {
		width: "100%",
		backgroundImage: "url('"+BackgroundCircles+"')",
		backgroundSize: "100%",
	},
})

function App() {

    const classes = useStyles();
    return (
        <main className={classes.content}>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/solution" component={Product} />
                <Route exact path="/support" component={Support} />
                <Redirect from="*" to="/" />
            </Switch>
        </main>
    );
}

export default App;
