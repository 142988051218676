import {
    Box,
    Container,
    Grid,
    List,
    ListItem,
    ListSubheader,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Link from '@mui/material/Link';

import LogoInterWhite from "../../assets/images/logo_inter_white.svg";
import IconApleStore from "../../assets/images/icon_apple_store.svg";
import IconGooglePlayStore from "../../assets/images/icon_google_play_store.svg";
import IconFB from "../../assets/images/icon_fb.svg";
import IconInstagram from "../../assets/images/icon_instagram.svg";
import IconLinkedin from "../../assets/images/icon_linkedin.svg";
import IconTiktok from "../../assets/images/icon_tiktok.svg";
import IconYoutube from "../../assets/images/icon_youtube.svg";

/* Contiene los datos de contacto de inter.mx, ligas a las páginas de los tipos de seguro disponibles, liga para
 el inicio de sesión de clientes, aviso de privacidad, ligas a las descargas de las apps de Inter y redes sociales  */

const Footer = () => {

    const { breakpoints } = useTheme();
    const isDesktopSmall = useMediaQuery(breakpoints.down(1101));
	const isTablet = useMediaQuery(breakpoints.down(768));
    const isTiny = useMediaQuery(breakpoints.down(431));

    const footerBox = { 
        backgroundColor: "#212121", 
        padding:"20px 0px 20px 0px" 
    }

    const footerBoxCopy = {
        textAlign:isTiny ? "left" : "center", 
        marginTop: isTiny ? "22px" : "45px", 
        color:"white", 
        fontSize:"12px", 
        fontWeight:"300"
    }

    const footerContainer = {
        paddingLeft: isTiny ? "32px" : ""
    }

    const footerGridContainer = {
        margin: "auto", 
        justifyContent: isDesktopSmall ? "center" : ""
    }

    const footerGridFirstColumn = {
        marginTop: isTiny ? "10px" : ""
    }

    const footerGridSecondColumn = {
        margin: isTablet ? "" : isDesktopSmall ? "auto" : ""
    }

    const footerGridThirdColumn = {
        marginTop: isTiny ? "4px" : ""
    }

    const footerImgLogo = {
        height:"31.5px",
        width:"auto",
        display:"block",
        margin: isTiny ? "22px auto 22px 0px" : isDesktopSmall ? "22px auto" : "22px 0px 0px 0px"
    }

    const footerLists = {
        width: '100%',
        maxWidth: 360,
        backgroundColor: "#212121",
        color: "white",
    }

    const footerListsTitles = { 
        backgroundColor: "#212121", 
        color: "white", 
        fontWeight: "bold" 
    }

    const footerListItems = {
        padding:"2px 0px 0px 0px"
    }

    const footerImgLogoSn = {
        height:"20px", 
        width:"auto", 
        display:"inline-block", 
    }
    const footerImgLogoYt = {
        height:"20px", 
        width:"auto", 
        display:"inline-block"
    }

    const footerImgLogoAppStore = {
        height:"35px", 
        width:"auto", 
        display:"inline-block", 
    }

    const footerImgLogoPlayStore = {
        height:"35px", 
        width:"auto"
    }

    const footerLinks = {
        textDecoration: "none", 
        color: "inherit"
    }

    const footerLinkAppStore = {
        textDecoration: "none", 
        color: "inherit",
        marginRight:"8px"
    }

    const footerLinksSn = {
        textDecoration: "none", 
        color: "inherit",
        marginRight: "28px"
    }
    
    return (
        <Box sx={footerBox}>
            <Container maxWidth="lg" style={footerContainer} >
                <Grid container maxWidth="lg" sx={footerGridContainer}>
                    <Grid item xs={12} md={isDesktopSmall ? 12 : 3} >
                        <Link href="https://inter.mx/" target="_blank">
                            <img src= {LogoInterWhite} alt= {"Logo Inter.mx"}
                                style={footerImgLogo}
                            />
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={isTablet ? 12 : "auto"} md={isDesktopSmall ? "auto" : 3} sx={footerGridFirstColumn}>
                        <List
                            sx={footerLists}
                            subheader={
                                <ListSubheader sx={footerListsTitles} disableGutters>
                                    Productos
                                </ListSubheader>
                            }
                        >
                            <ListItem sx={footerListItems}>
                                <Link href="https://cocheseguro.inter.mx" sx={footerLinks} target={"_blank"}>
                                    Coche Seguro
                                </Link>
                            </ListItem>
                            <ListItem sx={footerListItems}>
                                <Link href="https://medicoseguro.inter.mx" sx={footerLinks} target={"_blank"}>
                                    Médico Seguro
                                </Link>
                            </ListItem>
                            <ListItem sx={footerListItems}>
                                <Link href="https://productos.inter.mx/vida-segura" sx={footerLinks} target={"_blank"}>
                                    Vida Segura
                                </Link>
                            </ListItem>
                            <ListItem sx={footerListItems}>
                                <Link href="https://mascotasegura.inter.mx/seguro-mascota" sx={footerLinks} target={"_blank"}>
                                    Mascota Segura
                                </Link>
                            </ListItem>
                            <ListItem sx={footerListItems}>
                                <Link href="https://productos.inter.mx/bien-seguro" sx={footerLinks} target={"_blank"}>
                                    Bien Seguro
                                </Link>
                            </ListItem>
                            <ListItem sx={footerListItems}>
                                <Link href="https://vigo.inter.mx" sx={footerLinks} target={"_blank"}>
                                    Viaje Seguro
                                </Link>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={isTablet ? 12 : "auto"} md={isDesktopSmall ? "auto" : 3} sx={footerGridSecondColumn}>
                        <List
                            sx={footerLists}
                            subheader={
                                <ListSubheader sx={footerListsTitles} disableGutters>
                                    Contáctanos
                                </ListSubheader>
                            }
                        >
                            <ListItem sx={footerListItems}>
                                <Link href="tel:+525544246837" sx={footerLinks}>
                                    55 4424 6837
                                </Link>
                            </ListItem>
                            <ListItem sx={footerListItems}>
                                <Link href="mailto:hola@inter.mx" sx={footerLinks}>
                                    hola@inter.mx
                                </Link>
                            </ListItem>
                        </List>
                        <List
                            sx={footerLists}
                            subheader={
                                <ListSubheader sx={footerListsTitles} disableGutters>
                                    Clientes
                                </ListSubheader>
                            }
                        >
                            <ListItem sx={footerListItems}>
                                <Link href="https://www.interbi.mx/qlikview/FormLogin.htm" sx={footerLinks} target={"_blank"}>
                                    Login clientes corporativos
                                </Link>
                            </ListItem>
                            <ListItem sx={footerListItems}>
                                <Link href="https://inter.mx/aviso-de-privacidad" sx={footerLinks} target={"_blank"}>
                                    Aviso de privacidad
                                </Link>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={isTablet ? 12 : "auto"}  md={isDesktopSmall ? "auto" : 3} sx={footerGridThirdColumn}>
                        <List
                            sx={footerLists}
                            subheader={
                                <ListSubheader sx={footerListsTitles} disableGutters>
                                    Descarga nuestra app
                                </ListSubheader>
                            }
                        >
                            <ListItem sx={footerListItems}>
                                <Link href="https://apps.apple.com/mx/app/intermx/id1548367767?l=en" sx={footerLinkAppStore} target={"_blank"}>
                                    <img src= {IconApleStore} alt= {"Apple Store"}
                                        style={footerImgLogoAppStore}
                                    />
                                </Link>
                                <Link href="https://play.google.com/store/apps/details?id=grut.inter.mx" sx={footerLinks} target={"_blank"}>
                                    <img src= {IconGooglePlayStore} alt= {"Google Play Store"} style={footerImgLogoPlayStore} />
                                </Link>
                            </ListItem>
                        </List>
                        <List
                            sx={footerLists}
                            subheader={
                                <ListSubheader sx={footerListsTitles} disableGutters>
                                    Síguenos en redes sociales
                                </ListSubheader>
                            }
                        >
                            <ListItem sx={footerListItems}>
                                <Link href="https://www.facebook.com/intermx" sx={footerLinksSn} target={"_blank"}>
                                    <img src= {IconFB} alt= {"Facebook"}
                                        style={footerImgLogoSn}
                                    />
                                </Link>
                                <Link href="https://www.instagram.com/intermx" sx={footerLinksSn} target={"_blank"}>
                                    <img src= {IconInstagram} alt= {"Instagram"}
                                        style={footerImgLogoSn}
                                    />
                                </Link>
                                <Link href="https://www.linkedin.com/company/interproteccion/" sx={footerLinksSn} target={"_blank"}>
                                    <img src= {IconLinkedin} alt= {"Linkedin"}
                                        style={footerImgLogoSn}
                                    />
                                </Link>
                                <Link href="https://www.tiktok.com/@inter.mx" sx={footerLinksSn} target={"_blank"}>
                                    <img src= {IconTiktok} alt= {"Tiktok"}
                                        style={footerImgLogoSn}
                                    />
                                </Link>
                                <Link href="https://www.youtube.com/channel/UCZ8wH2JLzDYvvrN-JiG3roA" sx={footerLinks} target={"_blank"}>
                                    <img src= {IconYoutube} alt= {"Youtube"}
                                        style={footerImgLogoYt}
                                    />
                                </Link>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
                <Box sx={footerBoxCopy}>Copyright {new Date().getFullYear()} ® Interprotección</Box>
            </Container>
        </Box>
    );
};

export default Footer;