import { makeStyles } from "@mui/styles";

import { 
    Grid,
    Container,
    Typography,
    useTheme,
    useMediaQuery
 } from "@mui/material";

import NegocioHome from "../../../assets/images/negocio_home.png";
import ArrendadoraHome from "../../../assets/images/arrendadora_home.png";
import CorporativoHome from "../../../assets/images/corporativo_home.png";
import StartUpHome from "../../../assets/images/startup_home.png";
import NegocioHomeMobile from "../../../assets/images/negocio_home_mobile.png";
import ArrendadoraHomeMobile from "../../../assets/images/arrendadora_home_mobile.svg";
import CorporativoHomeMobile from "../../../assets/images/corporativo_home_mobile.svg";
import StartUpHomeMobile from "../../../assets/images/startup_home_mobile.svg";
import BottomGradient from "../../../assets/images/bottom_gradient.svg"
import BottomGradientTablet from "../../../assets/images/bottom_gradient_tablet.svg"
import BottomGradientTablet2 from "../../../assets/images/bottom2_gradient_tablet.svg"
import BottomGradientMobile from "../../../assets/images/bottom_gradient_mobile.svg"
import BottomGradientMobile2 from "../../../assets/images/bottom2_gradient_mobile.svg"

/* Contiene la descripción de las ventajas de las APIs para seguros */

const gradient = "url('"+BottomGradient+"') right bottom no-repeat"
const gradientTablet = "url('"+BottomGradientTablet2+"') right bottom no-repeat, url('"+BottomGradientTablet+"') right bottom no-repeat"
const gradientMobile = "url('"+BottomGradientMobile2+"') right bottom no-repeat, url('"+BottomGradientMobile+"') right bottom no-repeat"


const useStyles = makeStyles({
    root: {
        background: gradient,
    },
    rootTablet: {
        background: gradientTablet
    },
    rootMobile: {
        background: gradientMobile
    }
});

const Benefits = () => {

    const classes = useStyles();

    const { breakpoints } = useTheme();

    const isR1086 = useMediaQuery(breakpoints.down(1087));
    const isR901 = useMediaQuery(breakpoints.down(901));
    const isR865 = useMediaQuery(breakpoints.down(866));
    const isTablet = useMediaQuery(breakpoints.down(769));
	const isMobile = useMediaQuery(breakpoints.down(631));
    const isTiny =  useMediaQuery(breakpoints.down(430));

    const title = {
        fontWeight: 700,
        fontSize: isMobile ? "18px" : isTablet ? "18px" : "28px",
        lineHeight: isMobile ? "19.5px" : isTablet ? "22px" : "34px",
        textAlign: "center",
        marginBottom: "40px",
    }

    const benefitsImgLeftContainer = {
        paddingBottom: "80px",
        justifyContent: isMobile ? "center" : isTablet ? "flex-end" : "center",
    }

    const benefitsImgRightContainer = {
        paddingBottom: "80px",
        justifyContent: isMobile ? "center" : isTablet ? "space-between" : "center",
    }

    const benefitsGridImgLeft = {
        paddingRight: isMobile ? 0 : "48px",
        margin: isMobile ? "auto auto 24px" : "auto 0px auto 0px",
    }

    const benefitsGridImgRight = {
        paddingLeft: isMobile ? 0 : "48px",
        margin: isMobile ? "auto auto 24px" : "auto 0px auto 0px",
    }

    const benefitImg = {
        width: "100%",
        height: "auto",
        margin: "auto",
        display: "block",
    }

    const benefitGridTextRight = {
        margin: isMobile ? "auto 0px" : "auto 0px auto 0px",
        textAlign: isMobile ? "center" : "left",
    }

    const benefitGridTextLeft = {
        margin: isMobile ? "auto auto 24px" : "auto 0px auto 0px", 
        textAlign: isMobile ? "center"  : "right",
    }

    const benefitsTitle = {
        fontSize: isTablet ? "18px" : isR865 ? "20px" : "24px",
        lineHeight: isTablet ? "21.94px" : "29.26px",
        fontWeight: 700,
        marginBottom: "16px",
        padding: isTiny ? "" : isMobile ? "0px 18px" : "",
    }

    const benefitsSubtitle = {
        fontSize: isTablet ? "16px" : isR865 ? "17px" : "20px",
        lineHeight: isTablet ? "19.5px" : "24.38px",
        padding: isTiny ? "0px 12px" : isMobile ? "0px 18px" : "",
    }


    return (
        <div className={isMobile ? classes.rootMobile : isR901 ? classes.rootTablet : classes.root}>
        <Container maxWidth={"lg"}>
            <Typography
                sx={title}
            >
                INTER Connect es un ecosistema de APIs que se ajusta a tu negocio
            </Typography>
            <Grid container direction={{xs: "column", sm: isMobile ? "column" : "row"}} sx={benefitsImgLeftContainer}>
                <Grid
                    item 
                    xs={12} 
                    sm={ isMobile ? 12 : isR1086 ? 6.4 : 5.29}
                    sx={benefitsGridImgLeft} 
                > 
                    <img
                        src={isMobile ? NegocioHomeMobile : NegocioHome}
                        alt="Negocio"
                        loading="lazy"
                        style={benefitImg}
                    />
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    sm={ isMobile ? 12 : isR1086 ? 5.6 : 5.54170}
                    sx={benefitGridTextRight}
                >
                    <Typography
                        sx={benefitsTitle}
                    >
                        Venta de productos desde tu propia plataforma
                    </Typography>
                    <Typography
                        sx={benefitsSubtitle}
                    >
                        Cotiza, vende y opera seguros desde tu plataforma sin perder tu experiencia de usuario. Integra rápidamente y aumenta tus ingresos.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction={{xs: "column-reverse", sm: isMobile ? "column-reverse" : "row"}} sx={benefitsImgRightContainer} >
                <Grid 
                    item 
                    xs={12} 
                    sm={ isMobile ? 12 : isR1086 ? 5.6 : 5.54170}
                    sx={benefitGridTextLeft}
                >
                    <Typography
                        sx={benefitsTitle}
                    >
                        Seguros para arrendadoras y financieras
                    </Typography>
                    <Typography
                        sx={benefitsSubtitle}
                    >
                        Incorpora la cotización y contratación de seguros a tus procesos de originación de crédito de forma ágil.
                    </Typography>
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    sm={ isMobile ? 12 : isR1086 ? 6.4 : 5.29}
                    sx={benefitsGridImgRight}
                > 
                    <img
                        src={isMobile ? ArrendadoraHomeMobile : ArrendadoraHome}
                        alt="Arrendadora"
                        loading="lazy"
                        style={benefitImg}
                    />
                </Grid>
            </Grid>
            <Grid container direction={{xs: "column", sm: isMobile ? "column" : "row"}} sx={benefitsImgLeftContainer}>
                <Grid
                    item 
                    xs={12} 
                    sm={ isMobile ? 12 : isR1086 ? 6.4 : 5.29}
                    sx={benefitsGridImgLeft}
                >
                    <img
                        src={isMobile ? CorporativoHomeMobile : CorporativoHome}
                        alt="Corporativo"
                        loading="lazy"
                        style={benefitImg}
                    />
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    sm={ isMobile ? 12 : isR1086 ? 5.6 : 5.54170}
                    sx={benefitGridTextRight} 
                >
                    <Typography
                        sx={benefitsTitle}
                    >
                        Corporativos y pymes
                    </Typography>
                    <Typography
                        sx={benefitsSubtitle}
                    >
                        Administra de forma sencilla los seguros de tus activos físicos y los beneficios de tus colaboradores desde tus plataformas corporativas.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction={{xs: "column-reverse", sm: isMobile ? "column-reverse" : "row"}} sx={benefitsImgRightContainer} >
                <Grid 
                    item 
                    xs={12} 
                    sm={ isMobile ? 12 : isR1086 ? 5.6 : 5.54170}
                    sx={benefitGridTextLeft}
                >
                    <Typography
                        sx={benefitsTitle}
                    >
                        Startups, agencias Independientes y empresas en vías de digitalización
                    </Typography>
                    <Typography
                        sx={benefitsSubtitle}
                    >
                        Habilita tecnológicamente tu negocio o emprendimiento rápidamente sin necesidad de invertir grandes cantidades de dinero.
                    </Typography>
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    sm={ isMobile ? 12 : isR1086 ? 6.4 : 5.29}
                    sx={benefitsGridImgRight}
                >
                    <img
                        src={isMobile ? StartUpHomeMobile : StartUpHome}
                        alt="StartUp"
                        loading="lazy"
                        style={benefitImg}
                    />
                </Grid>
            </Grid>
        </Container>
        </div>
    );
};

export default Benefits;