import { SlideShowItem } from "../models/SlideShowItem"

import SlideCotizacion from "../assets/images/slide_cotizacion.png"
import SlideEmision from "../assets/images/slide_emision.png"
import SlidePerfilamiento from "../assets/images/slide_perfilador.png"

export const slideItems: SlideShowItem[] = [
    {
        title: "Cotización",
        subtitle: "Encuentra el mejor seguro a través de los servicios de cotización y multi comparador por aseguradora",
        image: SlideCotizacion
    },
    {
        title: "Emisión",
        subtitle: "Emite las pólizas de tus clientes de manera rápida y segura, para asegurar su satisfacción e incrementar el engagement con tu marca",
        image: SlideEmision
    },
    {
        title: "Perfilamiento",
        subtitle: "Obtener las características de los asegurados para perfilar y recomendar el mejor plan de seguros",
        image: SlidePerfilamiento
    }
]