import { useEffect } from "react";
import { Button, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import { makeStyles } from "@mui/styles";

import Navbar from "../components/navigation/Navbar";
import Benefits from "../components/screens/home/Benefits";
import Carrusel from "../components/screens/home/Carrusel";

import BannerHome from "../assets/images/banner_home.png";
import TopGradient from "../assets/images/top_gradient.svg";
import TopGradient2 from "../assets/images/top2_gradient.svg";
import TopGradientTablet from "../assets/images/top_gradient_tablet.svg";
import TopGradientTablet2 from "../assets/images/top2_gradient_tablet.svg";
import TopGradientMobile from "../assets/images/top_gradient_mobile.svg";
import TopGradientMobile2 from "../assets/images/top2_gradient_mobile.svg";
import LogoInterConnect from "../assets/images/logo_inter_connect.svg";

/* Página principal que integra un banner descriptivo del producto, la sección con las ventajas del uso de las 
APIs para seguros y un carrusel con ligas a la documentación de las APIs por tipo de seguro */

const gradient = "url('"+TopGradient2+"') left top no-repeat, url('"+TopGradient+"') left top no-repeat";
const gradientTablet = "url('"+TopGradientTablet2+"') left top no-repeat, url('"+TopGradientTablet+"') left top no-repeat";
const gradientMobile = "url('"+TopGradientMobile2+"') left top no-repeat, url('"+TopGradientMobile+"') left top no-repeat";

const useStyles = makeStyles({
    root: {
        background: gradient,
    },
    rootTablet: {
        background: gradientTablet
    },
    rootMobile: {
        background: gradientMobile
    }
});

const Home = () => {

    /* Limpiamos la sesión cada que entramos al home */
    sessionStorage.clear();

    const classes = useStyles();

    const { breakpoints } = useTheme();
    const isR1094 = useMediaQuery(breakpoints.down(1094));
    const isR1000 = useMediaQuery(breakpoints.down(1001));
    const isR901 = useMediaQuery(breakpoints.down(901));
    const isTablet = useMediaQuery(breakpoints.down(769));
    const isMobile = useMediaQuery(breakpoints.down("sm"));
    const isTiny = useMediaQuery(breakpoints.down(431));

    const bannerContainer = {
        marginTop: isMobile ? "40px" : isR1000 ? "0px" : "70px", 
        marginBottom: isTablet ? "40px" : "61px", 
        overflow:"hidden"
    }

    const bannerGridText = {
        textAlign: isR1000 ? "center" : "left",
        marginTop: isMobile ? "30px" : isR1000 ? "40px" : "",
        alignSelf: "center"
    }

    const bannerTitleLogo = {
        maxWidth: isTablet ? "280.5px" : "378px",
        minWidth: isTiny ? "209px" : isR1000 ? "234px" : "209px",
        marginTop: isR1000 ? "10px" : "0px",
        width: isR1000 ? "40%" : "100%",
        height: "auto"
    }

    const bannerTitle = {
        fontSize:  isTiny ? "18px" : isMobile ? "18px" : isR1000 ? "24px" : isR1094 ? "32px" : "30px",
        fontWeight: "400",
        lineHeight: isTiny ? "22px" : isMobile ? "26px" : isR1000 ? "30px" : "36.5px",
        marginTop: isTiny ? "16px" : isR1000 ? "24px" : "16px",
        width: isR1094 ? "100%" : "103%",
        padding: isTiny ? "0px" : isMobile ? "0px 7%" : isTablet ? "0px 10%" : isR1000 ? "0px 14%" : "0px",
        boxSizing: "border-box"
    }

    const bannerButton = {
        size: "large",
        my: 2,
        margin: isTiny ? "16px auto 10px auto" : isR1000 ? "24px auto 10px auto" : "40px 4% 10px auto",
        borderRadius: "60px",
        backgroundColor: '#039ECC',
        color: 'white',
        height: "48px",
        maxWidth: "340px",
        fontSize: "16px",
        fontWeight: "600",
        textTransform: "none",
        display: "block",
        textAlign: "center",
        lineHeight:"2.1",
        '&:hover': {
            backgroundColor: 'lightgray',
            color: "black"
        },
    }

    const bannerGridImg = {
        height:"auto",
        width:isR1000? "100%" : "117%",
        display:"block",
        marginLeft: isR1000 ? "0px" : "-44px",
    }

    /* Se agrega la función para que siempre se abra la página desde la parte superior de la misma */
    useEffect(() => {
        window.scrollBy(0, -10000);
    }, []);

    return (
        <div className={isMobile ? classes.rootMobile : isR901 ? classes.rootTablet : classes.root}>
        <>
            <Navbar />
            <Container maxWidth="lg" sx={bannerContainer}>
                <Grid container direction={isR1000 ? "column-reverse" : "row"}>
                    <Grid item  xs={isR1000 ? 12 : 4.5}
                        sx={bannerGridText}
                    >
                        <img
                            src={LogoInterConnect}
                            alt="Inter Connect"
                            style={bannerTitleLogo}
                        />
                        <Typography
                            sx={bannerTitle}
                        >
                            Somos el primer ecosistema Open Insurance de LATAM. Habilitamos la venta y operación de seguros en segundos.
                        </Typography>
                        <Button
                            key="Ver API"
                            href="/support"
                            color="info"
                            variant="contained"
                            sx={bannerButton}
                        >
                            PRUEBA NUESTRAS APIs
                        </Button>
                    </Grid>
                    <Grid item xs={isR1000 ? 12 : 7.5}>
                        <img src= {BannerHome} alt= {"Banner Home"}
                            style={bannerGridImg}
                        />
                    </Grid>
                </Grid>
            </Container>
            <Benefits />
            <Carrusel />
        </>
        </div>
    );
};

export default Home;