import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './assets/styles/index.css';
import { createTheme, ThemeProvider} from '@mui/material';

import App from './App';

import Footer from "./components/navigation/Footer";

const theme = createTheme({
    typography: {
      fontFamily: ['Montserrat'].join(','),
      fontSize: 16
    }
})

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <App />
                <Footer/>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
