export const industries = [
    'Industria Química',
    'Informática de consumo',
    'Ingeniería mecánica y eléctrica',
    'Medios de comunicación, cultura, gráficos',
    'Minería (carbón, otra minería)',
    'Petróleo y producción de gas',
    'Petróleo, producción de gas, refinación de petróleo',
    'Producción de metales básicos',
    'Servicios de correos y de telecomunicaciones',
    'Servicios de salud',
    'Servicios financieros, servicios profesionales',
    'Silvicultura, madera, celulosa, papel',
    'Servicios públicos (agua, gas, electricidad)',
    'Textiles, vestido, cuero, calzado',
    'Transporte (incluyendo aviación civil, ferrocarriles, transporte por carretera)',
    'Transporte marítimo, puertos, pesca, transporte interior',
    'Otro',
]