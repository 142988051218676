import React, { Component } from "react";
import Carousel from "./components/Carousel";
import { config } from "react-spring";
import { Link } from 'react-router-dom';
import { Box, Button, styled, Typography } from "@mui/material";

import CochesMotos from "../../../assets/images/coches_motos_h.png";
import GastoMedico from "../../../assets/images/gasto_medico_h.png";
import Mascota from "../../../assets/images/mascota_h.png";
import Vida from "../../../assets/images/vida_h.png";
import Danios from "../../../assets/images/danios_h.png";

/* Carrusel con las tarjetas que abren la página de la descripción y documentación de las APIs por tipo de
seguro */

const CajaStyled = styled('div')(({ theme }) => ({
    WebkitUserSelect: "none",
    WebkitTouchCallout: "none",
    MozUserSelect: "none",
    MsUserSelect: "none",
    userSelect: "none",
    width: "100%",
    margin: "0 auto",
    height: "600px",
    [theme.breakpoints.up(900)]: {
        padding: "0px 103px",
    },
    [theme.breakpoints.down(900)]: {
        padding: "0px",
    },
    [theme.breakpoints.down(769)]: {
        height: "520px",
    },  
    [theme.breakpoints.down(420)]: {
        width: "106%",
        margin: "0 auto 0 -3%",
    },
}));

const ParagraphStyled = styled('p')(({ theme }) => ({
    fontSize:"32px",
    padding:"40px 14px",
    [theme.breakpoints.down(850)]: {
        padding:"26px 14px 0px 14px",
    },
    [theme.breakpoints.down(769)]: {
        fontSize:"24px",
        padding:"12px 14px 6px 14px",
    },
    [theme.breakpoints.down(420)]: {
        fontSize:"24px",
    },
}));

const BoxStyled = styled('div')(({ theme }) => ({
  [theme.breakpoints.up(900)]: {
    width:"400px",
    height:"600px",
    boxShadow: "0 40px 90px 20px rgba(0, 0, 0, 0.05), 0 30px 40px 0px rgba(0, 0, 0, 0.2)",
    '& p.titlecm' : {
        margin: "62px 0px 24px",
    },
    '& p.subtitlecm' : {
        padding: "0px 44px",
    },
    '& img.imgcm' : {
        margin:"auto",
        width:"100%",
        WebkitUserDrag: "none",
        KhtmlUserDrag: "none",
        MozUserDrag: "none",
        OUserDrag: "none",
        UserDrag: "none",
    },
    '& p.titlePrx' : {
        margin: "60px 0px 24px 0px"
    },
    '& p.subtitlePrx' : {
        padding: "0px 55px",
    },
    '& div.cajaCarrusel' : {
        padding: "0px 103px",
    },
  },
  [theme.breakpoints.down(900)]: {
    width:"320px",
    height:"500px",
    boxShadow: "0 40px 90px 20px rgba(0, 0, 0, 0.05), 0 30px 40px 0px rgba(0, 0, 0, 0.2)",
    '& p.titlecm' : {
        margin: "54px 0px 24px",
    },
    '& p.subtitlecm' : {
        padding: "0px 14px",
    },
    '& img.imgcm' : {
        margin:"-20px auto 0px auto",
        width:"96%",
        WebkitUserDrag: "none",
        KhtmlUserDrag: "none",
        MozUserDrag: "none",
        OUserDrag: "none",
        UserDrag: "none",
    },
    '& p.titlePrx' : {
        margin: "64px 0px 24px 0px"
    },
    '& p.subtitlePrx' : {
        padding: "0px 16px",
    },
    '& div.cajaCarrusel' : {
        padding: "0px",
    },
  },
}));

export default class Carrusel extends Component {
    
  state = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.slow,
    goToSlideDelay: 200,
  };

  slides = [
    {
      key: "wer1",
      content:
        <BoxStyled
          style={{
            textAlign: "center",
            background: "white",
            borderRadius: "16px"
          }}
        >
          <Typography
            className="titlecm"
            style={{
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "24.4px", 
            }}
          >
            Coche y motos
          </Typography>
          <Typography
            className="subtitlecm"
            style={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "19.5px",
              margin: "0px 0px 24px 0px"
            }}
          >
            Tenemos las aseguradoras más importantes del mercado, contamos con soluciones para mercado individual y flotas
          </Typography>
          <img
            className="imgcm"
            src={CochesMotos}
            alt="coche y motos"
            height="auto"
          />
          <Link to="/solution"
            style={{
              textDecoration: "none",
            }}
            /* Asignamos en la sesión el valor para la categoria el cual se utiliza para mostrar el contenido
             correspondiente en el landing que contiene la descripción y documentación de las APIs por tipo de
             seguro  */
            onClick={() => sessionStorage.setItem("categoria", "vehiculos")}
          >
            <Button
              key="Ver API"
              color="info"
              variant="contained"
              size="large"
              sx={{
                my: 2,
                margin: "auto",
                borderRadius: "60px",
                backgroundColor: '#039ECC',
                color: 'white',
                height: "48px",
                minWidth: "220px",
                fontSize: "16px",
                fontWeight: "600",
                '&:hover': {
                  backgroundColor: 'lightgray',
                  color: "black"
                },
              }}
            >
              VER API
            </Button>
          </Link>
        </BoxStyled>
    },
    {
      key: "wer2",
      content:
        <BoxStyled
          style={{
            textAlign: "center",
            background: "white",
            borderRadius: "16px"
          }}
        >
          <Typography
            className="titlecm"
            style={{
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "24.4px", 
            }}
          >
            Gasto médico
          </Typography>
          <Typography
            className="subtitlecm"
            style={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "19.5px",
              margin: "0px 0px 24px 0px"
            }}
          >
            Ofrecemos múltiples opciones a través de un perfilamiento inteligente. Tenemos productos individuales y para grupos
          </Typography>
          <img
            className="imgcm"
            src={GastoMedico}
            alt="gasto médico"
            height="auto"
          />
          <Link to="/solution"
            style={{
              textDecoration: "none",
            }}
            /* Asignamos en la sesión el valor para la categoria el cual se utiliza para mostrar el contenido
             correspondiente en el landing que contiene la descripción y documentación de las APIs por tipo de
             seguro  */         
             onClick={() => sessionStorage.setItem("categoria", "medico")}
          >
            <Button
              key="Ver API"
              color="info"
              variant="contained"
              size="large"
              sx={{
                my: 2,
                margin: "auto",
                borderRadius: "60px",
                backgroundColor: '#039ECC',
                color: 'white',
                height: "48px",
                minWidth: "220px",
                fontSize: "16px",
                fontWeight: "600",
                '&:hover': {
                  backgroundColor: 'lightgray',
                  color: "black"
                },
              }}
            >
              VER API
            </Button>
          </Link>
        </BoxStyled>
    },
    {
      key: "wer3",
      content:
        <BoxStyled
          style={{
            textAlign: "center",
            background: "white",
            borderRadius: "16px"
          }}
        >
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "600",
              padding: "12px 0px",
              background: "#FFD60E",
              borderTopLeftRadius: "inherit",
              borderTopRightRadius: "inherit",
            }}
          >
            PRÓXIMAMENTE
          </Typography>
          <Typography
            className="titlePrx"
            style={{
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "24.4px",
            }}
          >
            Mascota
          </Typography>
          <Typography
            className="subtitlePrx"
            style={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "19.5px",
              margin: "0px 0px 24px 0px"
            }}
          >
            Incorpora rápidamente la cotización, venta y operación de seguros para mascotas
          </Typography>
          <img
            className="imgcm"
            src={Mascota}
            alt="mascota"
            height="auto"
          />
        </BoxStyled>
    },
    {
      key: "wer4",
      content:
        <BoxStyled
          style={{
            textAlign: "center",
            background: "white",
            borderRadius: "16px"
          }}
        >
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "600",
              padding: "12px 0px",
              background: "#FFD60E",
              borderTopLeftRadius: "inherit",
              borderTopRightRadius: "inherit",
            }}
          >
            PRÓXIMAMENTE
          </Typography>
          <Typography
            className="titlePrx"
            style={{
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "24.4px",
            }}
          >
            Vida
          </Typography>
          <Typography
            className="subtitlePrx"
            style={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "19.5px",
              margin: "0px 0px 24px 0px"
            }}
          >
            Nuestros servicios pueden cotizar varias opciones, la contratación es muy sencilla.
          </Typography>
          <img
            className="imgcm"
            src={Vida}
            alt="vida"
            height="auto"
          />
        </BoxStyled>
    },
    {
      key: "wer5",
      content:
        <BoxStyled
          style={{
            textAlign: "center",
            background: "white",
            borderRadius: "16px"
          }}
        >
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "600",
              padding: "12px 0px",
              background: "#FFD60E",
              borderTopLeftRadius: "inherit",
              borderTopRightRadius: "inherit",
            }}
          >
            PRÓXIMAMENTE
          </Typography>
          <Typography
            className="titlePrx"
            style={{
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "24.4px",
            }}
          >
            Daños
          </Typography>
          <Typography
            className="subtitlePrx"
            style={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "19.5px",
              margin: "0px 0px 24px 0px"
            }}
          >
            Contamos con integraciones para asegurar daños en los hogares de tus clientes
          </Typography>
          <img
            className="imgcm"
            src={Danios}
            alt="daños"
            height="auto"
          />
        </BoxStyled>
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
  });

  onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [e.target.name]: parseInt(e.target.value, 10) || 0
    });
  };

  render() {

    let vwx = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    return (
      <Box className="header-blue" style={{paddingBottom:"47px", overflow:"hidden"}}>
        <ParagraphStyled
            style={{
                fontWeight:"700",
                lineHeight:"32px",
                color:"white",
                textAlign:"center",
            }}
        >
            Productos multi-ramo
        </ParagraphStyled>
          <CajaStyled style={{ maxWidth: "1440px", boxSizing:"border-box" }}>
            <Carousel
              slides={this.slides}
              goToSlide={this.state.goToSlide}
              goToSlideDelay={this.state.goToSlideDelay}
              offsetRadius={ vwx < 600 ? 1 : this.state.offsetRadius}
              showNavigation={this.state.showNavigation}
              animationConfig={this.state.config}
            />
          </CajaStyled>
      </Box>
    );
  }
}
